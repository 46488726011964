import uuidv4 from 'uuid/v4';
import types from './mutation-types';
import translations from '@/services/translations';
import dateRanges from '@/utility/dateRanges';
import gravityGateway from '@/services/prematchGravityGateway';
import { sirConnect } from '@/utility/sportradarWidget';
import api from '@/api';

let oddinMatchesCountInterval;
export default {
  addNotification({ commit }, payload) {
    commit(types.ADD_NOTIFICATION, payload);
  },
  closeReofferUI({ commit, getters, dispatch }) {
    const { isMobile } = getters.settings;
    const { disableAutoSwitch } = getters.config;

    if (isMobile && !disableAutoSwitch) {
      dispatch('setSelectedTab', 'lastTickets');
    }
    commit(types.CLOSE_REOFFER_UI);
  },
  displayEmailVerificationError({ commit }, data) {
    commit(types.DISPLAY_EMAIL_VERIFICATION_ERROR, data);
  },
  globalNotificationAction({ commit, dispatch }, { actionName, payload }) {
    if (actionName === 'SMPrematch.TicketReoffer') {
      const localUuid = uuidv4();
      const reofferedTicket = _.assign({ localUuid }, payload);

      dispatch('addReOfferedTicket', reofferedTicket);
      commit(types.NOTIFICATION_REOFFER_ACTION, localUuid);
    }
  },
  handleNotificationExpired({
    getters, dispatch, commit,
  }, { payload }) {
    if (!payload.reOfferedTicket || getters.reOfferedTicketHandled) {
      dispatch('handleReOfferedTicket', false);
      return;
    }

    dispatch('removePendingTicket', payload.originalTicket);
    commit(types.REMOVE_PENDING_TICKET, payload.originalTicket);

    dispatch('addNotification', {
      type: 'error',
      message: getters.translations?.ticket_rejected || 'ticket_rejected',
      action: '',
    });
  },
  handleReOfferedTicket({ commit }, data = false) {
    commit(types.HANDLE_REOFFERED_TICKET, data);
  },
  notificationAction({ commit, dispatch }, { data, action, id }) {
    const ticketHash = (_.find((data || {}).ticketHashes, { type: 'Normal' }) || {}).hash;
    switch (action) {
      case 'reoffer': {
        commit(types.NOTIFICATION_REOFFER_ACTION, id);
        dispatch('notificationRemove', { data, action, id });
        break;
      }
      case 'rebetRejectedTicket': {
        dispatch('rebetTicket', ticketHash);
        dispatch('notificationRemove', { data, action, id });
        break;
      }
      case 'emailVerification': {
        gravityGateway.sendMessage('Router.GoTo', { url: '/player/profile' });
        break;
      }
      default: console.log('unknown action');
    }
  },
  notificationRemove({ commit }, data) {
    commit(types.NOTIFICATION_REMOVE, data.id);
  },
  removeAllNotifications({ commit }) {
    commit(types.REMOVE_ALL_NOTIFICATIONS);
  },
  setDefaultTimesValues({ commit, getters }, fullOffer) {
    const {
      defaultTimeOption, timezone, offerDateRange, timeDateOptions,
    } = getters;
    const timeSpan = defaultTimeOption === 'comingSoon' ? (_.filter(timeDateOptions, ['name', 'comingSoon'])[0] || {}).timeSpan : '';
    const timeValues = dateRanges.generateTimeRange({
      timeValue: fullOffer ? 'all' : defaultTimeOption,
      timeSpan,
      timezone,
      offerDateRange: {
        loadFullOffer: fullOffer || offerDateRange.loadFullOffer,
        days: offerDateRange.days,
      },
    });
    commit(types.SET_DEFAULT_TIME_VALUES, timeValues);
  },
  loginRequired() {
    gravityGateway.sendMessage('User.LoginRequired');
  },
  loadTranslations({ commit, getters }) {
    const { language } = getters.settings;
    const { id } = getters.settings.company;

    translations.get(language, id)
      .then((response) => {
        commit(types.SET_TRANSLATIONS, response);
      }).catch(() => {});
  },
  setTranslations({ commit }, data) {
    commit(types.SET_TRANSLATIONS, data);
  },
  toggleOnlineStatus({ commit }, payload) {
    commit(types.TOGGLE_CONNECTION_STATUS, payload);
  },
  toggleReofferPopup({ commit }) {
    commit(types.TOGGLE_REOFFER_POPUP);
  },
  toggleSidebar({ commit }, data) {
    commit(types.TOGGLE_SIDEBAR, data);
  },
  updateNotifications({ commit }, payload) {
    commit(types.UPDATE_NOTIFICATIONS, payload);
  },
  updateSettings({ commit }, data) {
    commit(types.UPDATE_SETTINGS, data);
  },
  updateConfig({ commit }, data) {
    commit(types.UPDATE_CONFIG, data);
  },
  updateUser({ commit }, user) {
    commit(types.UPDATE_USER, user);
  },
  updateUserBalance({ commit }, data) {
    commit(types.UPDATE_BALANCE, data);
  },
  updateUserToken({ commit }, data) {
    commit(types.UPDATE_USER_TOKEN, data);
  },
  verifyEmail() {
    if (gravityGateway) {
      gravityGateway.sendMessage('Router.ChangeRoute', { route: '/user/profile' });
    }
  },
  setCompanyName({ commit }, companyName) {
    commit(types.SET_COMPANY_NAME, companyName);
  },
  setCpvUuid({ commit }, value) {
    commit(types.SET_CPV_UUID, value);
  },
  updateMobileToggledTournaments({ commit }, tournamentId) {
    commit(types.UPDATE_MOBILE_TOGGLED_TOURNAMENTS, tournamentId);
  },
  toggleAllTournaments({ commit }, tournamentIds = []) {
    commit(types.MOBILE_TOGGLE_ALL_TOURNAMENTS, tournamentIds);
  },
  collapseAllTournaments({ commit }, tournamentIds = []) {
    commit(types.MOBILE_COLLAPSE_ALL_TOURNAMENTS, tournamentIds);
  },
  clearAllToggledTournaments({ commit }) {
    commit(types.MOBILE_CLEAR_ALL_TOGGLED_TOURNAMENTS);
  },
  handleSIRConnect({ commit, getters }) {
    const { language } = getters.settings;
    const {
      clientId,
      liveMatchTracker: { enabled: lmtLoaded = false },
      betAssist: { enabled: betAssistEnabled = false },
      betRecommendation: { enabled: betRecommendationEnabled = false },
    } = getters.config?.srWidgets || {};

    commit('SET_SIR_WIDGETS', {
      betAssist: betAssistEnabled,
      liveMatchTracker: lmtLoaded,
      betRecommendation: betRecommendationEnabled,
    });

    if (lmtLoaded || betAssistEnabled || betRecommendationEnabled) sirConnect(clientId, language);
  },
  updateBetAssistPopupId({ commit }, data = '') {
    commit(types.UPDATE_BET_ASSIST_POPUP_ID, data);
  },
  setScrollbarWidth({ commit }, data) {
    commit(types.SET_SCROLLBAR_WIDTH, data);
  },
  setPredefinedStakeState({ commit }, data = '') {
    commit(types.SET_PREDEFINED_STAKE_STATE, data);
  },
  setPredefinedStakeSelectedValue({ commit }, data) {
    commit(types.SET_PREDEFINED_STAKE_SELECTED_VALUE, data);
  },
  setLiveMatchesCount({ commit }, data) {
    commit(types.SET_LIVE_MATCHES_COUNT, data);
  },
  getOddinESportsConfig({ dispatch, getters }, { token, language, currency }) {
    if (!getters.config?.oddinESport?.enabled) return false;

    const { oddinESportsData } = getters;

    return api.getOddinESportsConfig().then((response) => {
      if (response.status !== 200) return false;

      return dispatch('updateOddinESportsData', {
        config: response.data,
      }).then(() => {
        if (token && !oddinESportsData.token) {
          return dispatch('getOddinESportsAuth', { token, language, currency });
        }
        return true;
      });
    }).catch(() => (dispatch('updateOddinESportsData', {
      config: { },
    })));
  },
  getOddinESportsAuth({ dispatch, getters }, { token, language, currency }) {
    const oddinEnabled = getters.config?.oddinESport?.enabled;
    const { oddinESportsData } = getters;
    if (!oddinEnabled || oddinESportsData?.token) return;

    api.getOddinESportsAuth({ token, language, currency }).then((response) => {
      if (response.status !== 200) return;

      dispatch('updateOddinESportsData', {
        token: response.data.token,
      });
    }).catch(err => console.error(err));
  },
  updateOddinESportsData({ commit, getters }, data) {
    if (!getters.config?.oddinESport?.enabled) return;

    const oddinData = {
      ...getters.oddinESportsData,
      ...data,
      currency: getters.settings?.currency,
      referrerUrl: getters.settings?.referrerUrl,
    };

    commit(types.UPDATE_ODDIN_ESPORT_DATA, oddinData);
  },
  getOddinMatchesCount({ commit, getters: { config } }) {
    const updateLiveOddinMatchesCount = () => api.getOddinMatchesCount()
      .then(({ data }) => {
        commit('SET_ODDIN_LIVE_MATCHES_COUNT', data?.live || 0);
        commit('SET_ODDIN_PREMATCH_MATCHES_COUNT', data?.prematch || 0);
      });

    clearInterval(oddinMatchesCountInterval);

    updateLiveOddinMatchesCount();
    oddinMatchesCountInterval = setInterval(
      () => updateLiveOddinMatchesCount(), config?.oddinESport?.refreshRate || 15000,
    );
  },
  showBettingAreaInBottomMenu() {
    gravityGateway.sendMessage('UI.Hide', { name: ['Betslip'] });
    gravityGateway.sendMessage('UI.Show', {
      name: 'BottomMenu',
      section: 'BettingArea',
    });
  },
  setMarketFromBetslip({ commit }, value) {
    commit(types.SET_MARKET_FROM_BETSLIP, value);
  },
};
