import Vue from 'vue';
import uuidv4 from 'uuid/v4';
import types from './mutation-types';

const notificationsTimeout = {};

export default {
  [types.ADD_NOTIFICATION](state, payload) {
    const id = payload.id || uuidv4();

    const clearNotification = () => {
      const notifications = _.filter(state.notifications, n => n.id !== id);
      state.notifications = notifications;
      delete notificationsTimeout[id];
      clearTimeout(notificationsTimeout[id]);
    };

    const addNotification = () => {
      state.notifications.push({
        id,
        type: payload.type,
        message: payload.message,
        action: payload.action,
      });
    };

    if (!payload.replace) addNotification();
    else {
      const foundNotificationIndex = _.findIndex(state.notifications, { id: payload.id });
      if (foundNotificationIndex > -1) {
        state.notifications[foundNotificationIndex].message = payload.message;
      } else addNotification();
    }

    if (notificationsTimeout[id]) {
      clearTimeout(notificationsTimeout[id]);
    }
    notificationsTimeout[id] = setTimeout(clearNotification, 10000);
  },
  [types.CLOSE_REOFFER_UI](state) {
    state.reOfferedTicketUIActive = false;
    state.reofferedTicketLocalUuid = '';
  },
  [types.DISPLAY_EMAIL_VERIFICATION_ERROR](state, data) {
    state.emailVerificationError = true;
    state.emailVerificationErrorMessage = data.message;
  },
  [types.NOTIFICATION_REMOVE](state, id) {
    const foundNotificationIndex = _.findIndex(state.notifications, { id });
    if (foundNotificationIndex === -1) return;
    state.notifications.splice(foundNotificationIndex, 1);
  },
  [types.NOTIFICATION_REOFFER_ACTION](state, id) {
    state.reOfferedTicketUIActive = true;
    state.reofferedTicketLocalUuid = id;
  },
  [types.HANDLE_REOFFERED_TICKET](state, data) {
    state.reOfferedTicketHandled = data;
  },
  [types.REMOVE_ALL_NOTIFICATIONS](state) {
    Vue.set(state, 'notifications', []);
    state.reOfferedTicketUIActive = false;
    state.reofferedTicketLocalUuid = '';
  },
  [types.SET_DEFAULT_TIME_VALUES](state, data) {
    state.defaultTimeValues = data;
  },
  [types.SET_TRANSLATIONS](state, translations) {
    Vue.set(state, 'translations', translations);
  },
  [types.TOGGLE_CONNECTION_STATUS](state, payload) {
    state.appOnline = payload;
  },
  [types.TOGGLE_REOFFER_POPUP](state) {
    state.reOfferedTicketUIActive = !state.reOfferedTicketUIActive;
  },
  [types.TOGGLE_SIDEBAR](state, data) {
    state.sidebarExpanded = data;
  },
  [types.UPDATE_NOTIFICATIONS](state, payload) {
    const notificationDuration = payload.duration || 7000;
    state.notifications.push({
      id: payload.requestUuid,
      type: payload.status,
      message: payload.message,
      messageDetails: payload.error,
      action: payload.action || '',
      actionText: payload.actionText || '',
      data: payload.data,
      elementIdValue: payload.elementIdValue || payload.requestUuid,
    });
    // eslint-disable-next-line
    const durationTimeout = setTimeout(() => {
      clearTimeout(durationTimeout);
      const filteredNotifications = _.filter(state.notifications,
        n => n.id !== payload.requestUuid);
      state.notifications = filteredNotifications;
    }, notificationDuration);
  },
  [types.UPDATE_SETTINGS](state, data) {
    const companyUuid = data.company.uuid || data.company.id;
    Vue.set(state.settings, 'user', data.user);
    state.settings.timezone = data.timezone;
    state.settings.company = data.company;
    state.settings.auth = data.auth;
    state.settings.company.uuid = companyUuid;

    state.settings.language = data.language;
    state.settings.currency = data.currency?.symbol;
    state.settings.isMobile = data.isMobile;
    state.settings.referrerUrl = decodeURIComponent(data.referrerUrl);
  },
  [types.UPDATE_CONFIG](state, config) {
    Vue.set(state, 'config', config);
  },
  [types.UPDATE_BALANCE](state, data) {
    Vue.set(state, 'balance', data);
  },
  [types.UPDATE_USER](state, user) {
    Vue.set(state.settings, 'user', user);
  },
  [types.UPDATE_USER_TOKEN](state, auth) {
    Vue.set(state.settings, 'auth', auth);
  },
  [types.SET_COMPANY_NAME](state, companyName) {
    state.companyName = companyName;
  },
  [types.SET_CPV_UUID](state, value) {
    state.cpvUuid = value;
  },
  [types.UPDATE_MOBILE_TOGGLED_TOURNAMENTS](state, tournamentId) {
    const { toggledOffTournaments } = state;
    const foundIndex = _.findIndex(toggledOffTournaments, tId => tId === tournamentId);
    if (foundIndex < 0) {
      toggledOffTournaments.push(tournamentId);
    } else {
      toggledOffTournaments.splice(foundIndex, 1);
    }
    Vue.set(state, 'toggledOffTournaments', toggledOffTournaments);
  },
  [types.MOBILE_TOGGLE_ALL_TOURNAMENTS](state, tournamentIds = []) {
    const updated = Array.from(new Set(state.toggledOffTournaments.concat(...tournamentIds)));
    Vue.set(state, 'toggledOffTournaments', updated);
  },
  [types.MOBILE_COLLAPSE_ALL_TOURNAMENTS](state, tournamentIds = []) {
    const updated = state.toggledOffTournaments.filter(t => tournamentIds.indexOf(t) === -1);
    Vue.set(state, 'toggledOffTournaments', updated);
  },
  [types.MOBILE_CLEAR_ALL_TOGGLED_TOURNAMENTS](state) {
    Vue.set(state, 'toggledOffTournaments', []);
  },
  [types.UPDATE_BET_ASSIST_POPUP_ID](state, data) {
    state.betAssistPopupId = data;
  },
  [types.SET_SIR_WIDGETS](state, { betAssist, liveMatchTracker, betRecommendation }) {
    state.lmtLoaded = liveMatchTracker;
    state.betAssist = betAssist;
    state.betRecommendation = betRecommendation;
  },
  [types.SET_SCROLLBAR_WIDTH](state, data) {
    state.browserScrollbarWidth = data;
  },
  [types.SET_PREDEFINED_STAKE_STATE](state, data) {
    state.predefinedStakeActive = data;
  },
  [types.SET_PREDEFINED_STAKE_SELECTED_VALUE](state, data) {
    state.predefinedStakeSelectedValue = data;
  },
  [types.SET_LIVE_MATCHES_COUNT](state, data) {
    state.liveMatchesCount = data;
  },
  [types.UPDATE_ODDIN_ESPORT_DATA](state, data) {
    state.oddinESportsData = data ? { ...state.oddinESportsData, ...data } : {};
  },
  [types.SET_ODDIN_LIVE_MATCHES_COUNT](state, data) {
    state.oddinLiveMatchesCount = data;
  },
  [types.SET_ODDIN_PREMATCH_MATCHES_COUNT](state, data) {
    state.oddinPrematchMatchesCount = data;
  },
  [types.SET_MARKET_FROM_BETSLIP](state, value) {
    state.marketSelectedFromBetslip = value;
  },
};
