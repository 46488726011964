export default {
  default: {
    cashoutEnabled: true,
    googleTagManagerContainerID: {
      dev: 'GTM-KJ9Z6DQ',
      staging: 'GTM-KJ9Z6DQ',
      production: 'GTM-KJ9Z6DQ',
    },
    liveGoogleTagManagerContainerID: {
      dev: 'GTM-KJ9Z6DQ',
      staging: 'GTM-KJ9Z6DQ',
      production: 'GTM-KJ9Z6DQ',
    },
    dc: '',
    dcUrl: '',
    stickyHeaderEnabled: true,
    headerGridFilter: true,
    sidebarExpandable: true,
    displayTranslationKeys: true,
    mobTournamentsToggleUniqSettings: false,
    displayShowBarcodeButton: false,
    betslipToggleHeaderDisplay: false,
    betslipBonusProgressBar: false,
    betSelectionEnabled: false,
    betslipUndoEnabled: false,
    defaultGridMode: 'tournaments',
    waysActive: true,
    disableLists: false,
    disableCoupons: false,
    historyTicketCount: 10,
    scoutEnabled: false,
    eventviewSortFavorites: false,
    vaixTrackerToken: '',
    srWidgets: {
      clientId: 'c7aaeeb96ee79a50be0b6ea5c11b18f1',
      silent: false,
      liveMatchTracker: {
        enabled: true,
      },
      betAssist: {
        enabled: false,
      },
    },
    mockedCompanyData: {
      companyUuid: 'b99752b3-443c-4c80-b559-945a95c4b805',
      language: 'en',
      timezone: 'Europe/Belgrade',
    },
    bannersEnabled: false,
    localBetslipToggle: {
      active: false,
      persistent: false,
    },
    outcomesRounded: false,
    oddinESport: {
      enabled: false,
      position: 4,
    },
    esportsHeader: {
      active: false,
      position: 2,
    },
  },
  balkanbet: {
    googleTagManagerContainerID: {
      dev: '',
      staging: '',
      production: '',
    },
    liveGoogleTagManagerContainerID: {
      dev: '',
      staging: '',
      production: '',
    },
    dc: 'rs',
    dcUrl: '-rs',
    dcUrlFragmentCl: 'rs',
    cpvUuid: '3c474fe9-7bf3-4ba4-93db-3b09564b27f6',
    vaixTrackerToken: '5a171524-62af-11ec-b8ba-ca0647acd8b8',
  },
  balkanbetstaging: {
    googleTagManagerContainerID: {
      dev: '',
      staging: '',
      production: '',
    },
    dc: 'en',
    dcUrl: '',
    cpvUuid: '424cb0db-6c23-4328-9621-024116ca5aa7',
  },
  prvafirma: {
    googleTagManagerContainerID: {
      dev: 'GTM-KJ9Z6DQ',
      staging: 'GTM-KJ9Z6DQ',
      production: 'GTM-KJ9Z6DQ',
    },
    dc: 'en',
    dcUrl: '',
    displayShowBarcodeButton: true,
    cpvUuid: '6f70074d-28f5-4941-89ba-2199d794cb9f',
    vaixTrackerToken: '5a171524-62af-11ec-b8ba-ca0647acd8b8',
  },
  bugbet: {
    googleTagManagerContainerID: {
      dev: 'GTM-KJ9Z6DQ',
      staging: 'GTM-KJ9Z6DQ',
      production: 'GTM-KJ9Z6DQ',
    },
    headerGridFilter: true,
    sidebarExpandable: true,
  },
  winner: {
    googleTagManagerContainerID: {
      dev: '',
      staging: '',
      production: '',
    },
    dc: 'ro',
    dcUrl: '-ro',
    dcUrlFragmentCl: 'ro',
    displayTranslationKeys: false,
    displayShowBarcodeButton: true,
    betslipToggleHeaderDisplay: true,
    waysActive: false,
    cpvUuid: '6442b1ed-e06b-45df-810c-cff5a89900f8',
  },
  seven: {
    googleTagManagerContainerID: {
      dev: '',
      staging: '',
      production: '',
    },
    dc: 'ro',
    dcUrl: '-ro',
    displayTranslationKeys: false,
    betslipToggleHeaderDisplay: true,
    cpvUuid: '6442b1ed-e06b-45df-810c-cff5a89900f8',
  },
  topbet: {
    googleTagManagerContainerID: {
      dev: 'GTM-KJ9Z6DQ',
      staging: 'GTM-KJ9Z6DQ',
      production: 'GTM-KJ9Z6DQ',
    },
    dc: 'rs2',
    dcUrl: '-rs2',
    dcUrlFragmentCl: 'rs-2',
    headerGridFilter: false,
    sidebarExpandable: false,
    mobTournamentsToggleUniqSettings: true,
    betslipToggleHeaderDisplay: true,
    categoryIconRatio: '0-5',
    cpvUuid: '2cd8c10d-738f-4e5c-b2b8-e6805baa88c6',
  },
  firmazadev: {
    googleTagManagerContainerID: {
      dev: '',
      staging: '',
      production: '',
    },
    dc: 'en',
    cpvUuid: 'f6f19d06-04a8-4fe8-b366-79b3894f0785',
  },
  expo: {
    googleTagManagerContainerID: {
      dev: 'GTM-KJ9Z6DQ',
      staging: 'GTM-KJ9Z6DQ',
      production: 'GTM-KJ9Z6DQ',
    },
    dc: 'en',
    dcUrl: '',
    cpvUuid: 'f79d2fc8-6bee-487f-928d-285854647629',
  },
  lutrijabosnaihercegovina: {
    googleTagManagerContainerID: {
      dev: 'GTM-KJ9Z6DD',
      staging: 'GTM-KJ9Z6DD',
      production: 'GTM-KJ9Z6DD',
    },
    dc: 'de',
    dcUrl: '-de',
    dcUrlFragmentCl: 'de',
    cpvUuid: '30431a0c-9602-4c3a-98b0-58a29b98ec51',
  },
  lutrijabihde: {
    googleTagManagerContainerID: {
      dev: 'GTM-KJ9Z6DD',
      staging: 'GTM-KJ9Z6DD',
      production: 'GTM-KJ9Z6DD',
    },
    dc: 'de',
    dcUrl: '-de',
    dcUrlFragmentCl: 'de',
    cpvUuid: '30431a0c-9602-4c3a-98b0-58a29b98ec51',
  },
  futurebet: {
    googleTagManagerContainerID: {
      dev: 'GTM-KJ9Z6DD',
      staging: 'GTM-KJ9Z6DD',
      production: 'GTM-KJ9Z6DD',
    },
    dc: 'en',
    dcUrl: '',
    cpvUuid: 'f1610c2b-a5d5-4b41-af07-569794f68253',
  },
  bmk: {
    googleTagManagerContainerID: {
      dev: 'GTM-KJ9Z6DQ',
      staging: 'GTM-KJ9Z6DQ',
      production: 'GTM-KJ9Z6DQ',
    },
    dc: 'en',
    dcUrl: '',
    cpvUuid: '2794097d-8bfb-4ca1-aa85-5b44c9a166d5',
  },
  hemingwaynew: {
    googleTagManagerContainerID: {
      dev: 'GTM-KJ9Z6DQ',
      staging: 'GTM-KJ9Z6DQ',
      production: 'GTM-KJ9Z6DQ',
    },
    dc: 'en',
    dcUrl: '',
    cpvUuid: '24f6173e-a42c-4ca1-b2b9-57843f57130b',
  },
  simacommunication: {
    googleTagManagerContainerID: {
      dev: 'GTM-KJ9Z6DQ',
      staging: 'GTM-KJ9Z6DQ',
      production: 'GTM-KJ9Z6DQ',
    },
    dc: 'en',
    dcUrl: '',
    cpvUuid: '9169ed60-bf03-42de-acd5-01e63ae3ed2a',
  },
  sigma: {
    googleTagManagerContainerID: {
      dev: 'GTM-KJ9Z6DQ',
      staging: 'GTM-KJ9Z6DQ',
      production: 'GTM-KJ9Z6DQ',
    },
    dc: 'en',
    dcUrl: '',
    cpvUuid: '7bed7989-68ba-4ba8-aec5-6c3f3c7be0ee',
  },
  aceline: {
    googleTagManagerContainerID: {
      dev: 'GTM-KJ9Z6DQ',
      staging: 'GTM-KJ9Z6DQ',
      production: 'GTM-KJ9Z6DQ',
    },
    dc: 'en',
    dcUrl: '',
    cpvUuid: 'f20c7658-fca2-4511-b4ed-f3f2945b84f1',
  },
  wettnet: {
    googleTagManagerContainerID: {
      dev: 'GTM-KJ9Z6DQ',
      staging: 'GTM-KJ9Z6DQ',
      production: 'GTM-KJ9Z6DQ',
    },
    dc: 'en',
    dcUrl: '',
    cpvUuid: '868b25dd-3f8f-424f-9b60-ccccacdf33b5',
  },
  isiparyaj: {
    googleTagManagerContainerID: {
      dev: 'GTM-KJ9Z6DQ',
      staging: 'GTM-KJ9Z6DQ',
      production: 'GTM-KJ9Z6DQ',
    },
    dc: 'en',
    dcUrl: '',
    cpvUuid: 'c8faf855-4b05-4d48-958c-e19793b7e5d4',
  },
  atlantikretail: {
    googleTagManagerContainerID: {
      dev: 'GTM-KJ9Z6DQ',
      staging: 'GTM-KJ9Z6DQ',
      production: 'GTM-KJ9Z6DQ',
    },
    dc: 'en',
    dcUrl: '',
    cpvUuid: '8fd16314-70a8-4424-b345-8b1b894a3bfc',
  },
  playbetr: {
    googleTagManagerContainerID: {
      dev: '',
      staging: '',
      production: '',
    },
    dc: 'en',
    dcUrl: '',
    cpvUuid: 'bc593573-c8f9-450b-b904-97b28f25ea6f',
  },
  carrus: {
    googleTagManagerContainerID: {
      dev: 'GTM-KJ9Z6DQ',
      staging: 'GTM-KJ9Z6DQ',
      production: 'GTM-KJ9Z6DQ',
    },
    dc: 'en',
    dcUrl: '',
    cpvUuid: '6f70074d-28f5-4941-89ba-2199d794cb9f',
  },
  betflip: {
    googleTagManagerContainerID: {
      dev: 'GTM-KJ9Z6DQ',
      staging: 'GTM-KJ9Z6DQ',
      production: 'GTM-KJ9Z6DQ',
    },
    dc: 'en',
    dcUrl: '',
    cpvUuid: '',
  },
  betcoinsportsxmb: {
    googleTagManagerContainerID: {
      dev: 'GTM-KJ9Z6DQ',
      staging: 'GTM-KJ9Z6DQ',
      production: 'GTM-KJ9Z6DQ',
    },
    dc: 'en',
    dcUrl: '',
    cpvUuid: '2d8d5f9f-30b3-4fe1-8a97-3d833f3a3d78',
  },
  '2xsportbet': {
    googleTagManagerContainerID: {
      dev: 'GTM-KJ9Z6DQ',
      staging: 'GTM-KJ9Z6DQ',
      production: 'GTM-KJ9Z6DQ',
    },
    dc: 'en',
    dcUrl: '',
    cpvUuid: '678f0276-0094-4cf7-90d0-f4d701a582a1',
  },
  victory: {
    googleTagManagerContainerID: {
      dev: 'GTM-KJ9Z6DQ',
      staging: 'GTM-KJ9Z6DQ',
      production: 'GTM-KJ9Z6DQ',
    },
    dc: 'en',
    dcUrl: '',
  },
  bhplay: {
    googleTagManagerContainerID: {
      dev: 'GTM-KJ9Z6DQ',
      staging: 'GTM-KJ9Z6DQ',
      production: 'GTM-KJ9Z6DQ',
    },
    dc: 'en',
    dcUrl: '',
  },
  sportnaloterija: {
    googleTagManagerContainerID: {
      dev: 'GTM-KJ9Z6DQ',
      staging: 'GTM-KJ9Z6DQ',
      production: 'GTM-KJ9Z6DQ',
    },
    dc: 'en',
    dcUrl: '',
    cpvUuid: 'a1667c4b-dcb2-4d21-ba81-10e755dff6b5',
    tournamentLang: 'sl',
  },
  lutrijaslovenije: {
    googleTagManagerContainerID: {
      dev: 'GTM-KJ9Z6DQ',
      staging: 'GTM-KJ9Z6DQ',
      production: 'GTM-KJ9Z6DQ',
    },
    dc: 'en',
    dcUrl: '',
    cpvUuid: 'a1667c4b-dcb2-4d21-ba81-10e755dff6b5',
    tournamentLang: 'sl',
  },
  betfirstnltest: {
    cashoutEnabled: false,
  },
  stanleybet: {
    dc: 'ro',
    dcUrl: '-ro2',
    dcUrlFragmentCl: 'ro-2',
    tournamentLang: 'ro',
  },
  stanleybetro2: {
    dc: 'ro',
    dcUrl: '-ro2',
    dcUrlFragmentCl: 'ro-2',
    tournamentLang: 'ro',
  },
  maxbetromania: {
    dc: 'ro',
    dcUrl: '-ro',
    dcUrlFragmentCl: 'ro',
  },
};
